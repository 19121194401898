const cookie = {

  check () {
    const name = 'cookie_prevent='
    const t = document.cookie.split(';')
    let checkCookie = null
    for (let i = 0; i < t.length; i++) {
      let c = t[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) !== -1) { checkCookie = c.substring(name.length, c.length) }
    }
    if (checkCookie === '1') {
      $('#cookie-prevent').remove()
    } else {
      $('#cookie-prevent').removeClass('d-none').stop(true, true).show().animate({ opacity: 1, bottom: '0px' }, 500)
    }
    return checkCookie
  },

  accept () {
    document.cookie = 'cookie_prevent=1; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
    $('#cookie-prevent').stop(true, true).animate({ opacity: 0, bottom: '-100px' }, 500, function () { $(this).remove() })
  },

  reject () {
    document.cookie = 'cookie_prevent=0; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
    document.location.reload()
  },

  init () {
    if (this.check() !== 1) {
      $('#cookie-prevent-accept').on('click', function () {
        cookie.accept()
      })
    }
    $('#deactivate-cookie').on('click', function () {
      cookie.reject()
    })
  }

}

$(document).ready(function () {
  cookie.init()
})
